export default defineNuxtRouteMiddleware(async (to) => {
  const { $i18n, $pinia } = useNuxtApp();
  const { routes } = useAppStore($pinia);
  const router = useRouter();

  const dynamicPageInfo = identifyDynamicPage($i18n.locale.value, routes, to, router);
  if (dynamicPageInfo && dynamicPageInfo.redirect) {
    return navigateTo({ path: dynamicPageInfo.path, query: dynamicPageInfo.query }, { redirectCode: 301 });
  }
});
